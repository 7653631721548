export const Appplications = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 678.62 510.81641"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>applications</title>
    <path
      d="m353.43203,89.5h-166.78833c-6.39966,0-11.60596-5.20703-11.60596-11.60645V11.60547c0-6.39941,5.2063-11.60547,11.60596-11.60547h166.78833c6.39941,0,11.60571,5.20605,11.60571,11.60547v66.28809c0,6.39941-5.2063,11.60645-11.60571,11.60645Z"
      fill="#ff2e92"
      strokeWidth="0"
    />
    <path
      d="m353.43203,292.5h-166.78833c-6.39966,0-11.60596-5.20703-11.60596-11.60645v-66.28809c0-6.39941,5.2063-11.60547,11.60596-11.60547h166.78833c6.39941,0,11.60571,5.20605,11.60571,11.60547v66.28809c0,6.39941-5.2063,11.60645-11.60571,11.60645Z"
      fill="#d8d8d8"
      strokeWidth="0"
    />
    <path
      d="m352.44155,189.81641h-65.57031c-6.73535,0-12.21484-5.47949-12.21484-12.21484v-63.57031c0-6.73535,5.47949-12.21484,12.21484-12.21484h65.57031c6.73535,0,12.21484,5.47949,12.21484,12.21484v63.57031c0,6.73535-5.47949,12.21484-12.21484,12.21484Z"
      fill="#d8d8d8"
      strokeWidth="0"
    />
    <path
      d="m252.44155,189.81641h-65.57031c-6.73535,0-12.21484-5.47949-12.21484-12.21484v-63.57031c0-6.73535,5.47949-12.21484,12.21484-12.21484h65.57031c6.73535,0,12.21484,5.47949,12.21484,12.21484v63.57031c0,6.73535-5.47949,12.21484-12.21484,12.21484Z"
      fill="#d8d8d8"
      strokeWidth="0"
    />
    <path
      d="m678.62,509.31641c0,.83002-.66998,1.5-1.5,1.5H1.5c-.83,0-1.5-.66998-1.5-1.5s.67-1.5,1.5-1.5h675.62c.83002,0,1.5.66998,1.5,1.5Z"
      fill="#2f2e43"
      strokeWidth="0"
    />
    <polygon
      points="521.68062 454.1794 507.86369 468.68747 493.13827 455.07449 506.95519 440.56641 521.68062 454.1794"
      fill="#ec9c9f"
      strokeWidth="0"
    />
    <path
      d="m532.7171,469.03852l-29.36998,30.83913c-2.51029,2.63586-5.64561,4.71841-9.06708,6.0225l-8.18831,3.12104c-1.69302.64532-3.64239.27152-4.96618-.95226-1.55248-1.4352-1.9454-3.73444-.9555-5.59137l7.87618-14.77439,8.40469-29.96186.09142.0563c2.28636,1.40797,5.75424,3.52058,6.00144,3.61003,4.35229.15413,7.46798-.94048,9.26147-3.25263,3.12825-4.03295,1.1436-10.47122,1.12319-10.53582l-.0156-.04942.03968-.03397c.91186-.77861,1.87235-1.09015,2.85488-.92602,2.08576.34851,3.57774,2.69144,3.79609,3.05188,1.95729-.08245,7.96683,4.62411,8.45043,5.00551,2.95043-.00791,5.11335.69168,6.42839,2.07965,1.14975,1.21342,1.65035,2.93583,1.48814,5.11937-.1951,2.62712-1.35051,5.1743-3.25335,7.17233Z"
      fill="#090814"
      strokeWidth="0"
    />
    <rect
      x="485.53653"
      y="470.883"
      width="20.19271"
      height="19.89466"
      fill="#ec9c9f"
      strokeWidth="0"
    />
    <path
      d="m502.28271,508.87456h-42.92269c-3.66865,0-7.35544-.82768-10.66185-2.3936l-7.91297-3.74756c-1.6361-.77484-2.69329-2.4312-2.69329-4.21969,0-2.09747,1.43298-3.95303,3.48476-4.51242l16.32485-4.45054,27.92779-14.46289.02089.1042c.52231,2.60611,1.33153,6.54329,1.43447,6.7821,2.86399,3.2325,5.8061,4.72207,8.74468,4.42857,5.12558-.51197,8.533-6.34331,8.56684-6.40218l.02591-.04502.05229.00526c1.20024.12238,2.08806.59931,2.63887,1.41755,1.16923,1.73705.45596,4.41216.33857,4.81568,1.40031,1.34986,2.0287,8.88807,2.0773,9.49651,2.0247,2.11441,2.98687,4.14713,2.85936,6.04168-.11142,1.65636-1.04376,3.19459-2.77094,4.57215-2.07801,1.65744-4.75395,2.57022-7.53486,2.57022Z"
      fill="#090814"
      strokeWidth="0"
    />
    <polygon
      points="508.12646 220.32345 436.57385 220.35831 412.72298 369.14934 484.27559 454.19271 508.12646 432.93187 451.57954 369.14934 508.12646 220.32345"
      fill="#090814"
      strokeWidth="0"
    />
    <polygon
      points="465.83465 242.25667 508.12646 220.32345 508.12646 475.45355 484.27559 476.1085 465.83465 242.25667"
      fill="#090814"
      strokeWidth="0"
    />
    <path
      d="m423.47218,279.98992l.50385-23.22496-13.56028-.29418-.50385,23.22496c-2.53,2.34865-4.20012,5.99798-4.28988,10.13548-.15706,7.23954,4.57252,13.21364,10.56385,13.34362,5.99133.12998,10.97555-5.63338,11.1326-12.87293.08976-4.1375-1.42063-7.85583-3.84629-10.31199Z"
      fill="#ec9c9f"
      strokeWidth="0"
    />
    <path
      d="m487.7749,53.17202s19.46513-.04207,12.19353-18.79784c-7.2716-18.75577-20.81174-10.35779-20.81174-10.35779,0,0-5.70536,3.01366-4.5291,9.73748"
      fill="#090814"
      strokeWidth="0"
    />
    <path
      d="m489.72302,62.1686c0-14.38025-11.65746-26.03763-26.03754-26.03763s-26.03772,11.65737-26.03772,26.03763c0,11.6623,7.66783,21.53259,18.23664,24.84743l5.03369,33.26563,25.66039-21.3836s-5.54354-7.06083-8.51633-15.02245c7.02635-4.66298,11.66087-12.64233,11.66087-21.70702Z"
      fill="#ec9c9f"
      strokeWidth="0"
    />
    <path
      d="m476.19508,84.16406s3.45773-13.25664-2.19606-19.35213c-5.6409-6.0816-6.36957,3.38112-6.36957,3.38112l-4.07028-.88484s.70788-9.20238-7.25572-10.7951l5.1321-10.97207s-19.64839,3.81501-20.18995,1.912c-4.26122-14.97371,36.39041-27.76478,47.62013-4.92047,17.46591,35.53044-12.67063,41.6315-12.67063,41.6315Z"
      fill="#090814"
      strokeWidth="0"
    />
    <path
      d="m453.1968,107.36023h-.00003c-18.90326,3.69375-33.0921,19.43311-34.81262,38.61688l-11.76634,131.19472h20.64802l25.93097-169.81159Z"
      fill="#ff2e92"
      strokeWidth="0"
    />
    <polyline
      points="433.67318 211.88789 430.45745 248.93102 517.74722 223.25756 507.11107 196.48381 526.91631 151.00511 486.48715 93.05645 454.21195 95.25703 441.74198 111.39463"
      fill="#ff2e92"
      strokeWidth="0"
    />
    <path
      d="m504.85762,247.06728l12.17487-19.78447-11.55149-7.1085-12.17487,19.78447c-3.37019.74757-6.65594,3.05208-8.82488,6.57666-3.79508,6.16709-2.73415,13.7125,2.36964,16.85325,5.10379,3.14075,12.31767.68745,16.11275-5.47964,2.16894-3.52458,2.74533-7.49636,1.89399-10.84176Z"
      fill="#ec9c9f"
      strokeWidth="0"
    />
    <path
      d="m479.8854,93.78997l7.03843-.29198,12.03331,9.09431c14.25886,2.43159,18.39403,3.11332,22.76287,12.53204,1.83579,3.95777,3.05281,8.17407,3.73324,12.48349l10.18023,64.47481-25.58831,61.6163-16.95628-19.07171,18.12649-48.17314-3.01123-11.52302"
      fill="#ff2e92"
      strokeWidth="0"
    />
  </svg>
)
