export const Favorites = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 728.34646 523.59055"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>favorites</title>
      <path
        d="M947.17323,711.20472h-437a17.01916,17.01916,0,0,1-17-17v-489a17.01917,17.01917,0,0,1,17-17h437a17.01917,17.01917,0,0,1,17,17v489A17.01916,17.01916,0,0,1,947.17323,711.20472Zm-437-521a15.017,15.017,0,0,0-15,15v489a15.017,15.017,0,0,0,15,15h437a15.017,15.017,0,0,0,15-15v-489a15.017,15.017,0,0,0-15-15Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#3f3d56"
      />
      <rect x="258.34646" y="41.59033" width="469" height="2" fill="#3f3d56" />
      <circle cx="281.34646" cy="22" r="8" fill="#ff2e92" />
      <circle cx="306.34646" cy="22" r="8" fill="#ff2e92" />
      <circle cx="331.34646" cy="22" r="8" fill="#ff2e92" />
      <path
        d="M884.67323,303.20472h-312a13,13,0,0,1,0-26h312a13,13,0,0,1,0,26Zm-312-24a11,11,0,0,0,0,22h312a11,11,0,0,0,0-22Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#3f3d56"
      />
      <path
        d="M807.52756,405.79528h-142a8,8,0,0,1,0-16h142a8,8,0,0,1,0,16Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#ccc"
      />
      <path
        d="M889.52756,432.79528h-224a8,8,0,0,1,0-16h224a8,8,0,0,1,0,16Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#ccc"
      />
      <path
        d="M612.3189,434.29528h-43a9.51081,9.51081,0,0,1-9.5-9.5v-27a9.5108,9.5108,0,0,1,9.5-9.5h43a9.5108,9.5108,0,0,1,9.5,9.5v27A9.51081,9.51081,0,0,1,612.3189,434.29528Zm-43-44a7.50835,7.50835,0,0,0-7.5,7.5v27a7.50836,7.50836,0,0,0,7.5,7.5h43a7.50836,7.50836,0,0,0,7.5-7.5v-27a7.50835,7.50835,0,0,0-7.5-7.5Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#ccc"
      />
      <path
        d="M807.52756,500.79528h-142a8,8,0,0,1,0-16h142a8,8,0,0,1,0,16Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#ccc"
      />
      <path
        d="M889.52756,527.79528h-224a8,8,0,1,1,0-16h224a8,8,0,0,1,0,16Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#ccc"
      />
      <path
        d="M612.3189,529.29528h-43a9.51081,9.51081,0,0,1-9.5-9.5v-27a9.5108,9.5108,0,0,1,9.5-9.5h43a9.5108,9.5108,0,0,1,9.5,9.5v27A9.51081,9.51081,0,0,1,612.3189,529.29528Zm-43-44a7.50835,7.50835,0,0,0-7.5,7.5v27a7.50836,7.50836,0,0,0,7.5,7.5h43a7.50836,7.50836,0,0,0,7.5-7.5v-27a7.50835,7.50835,0,0,0-7.5-7.5Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#ccc"
      />
      <path
        d="M807.52756,595.79528h-142a8,8,0,0,1,0-16h142a8,8,0,0,1,0,16Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#f2f2f2"
      />
      <path
        d="M889.52756,622.79528h-224a8,8,0,0,1,0-16h224a8,8,0,0,1,0,16Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#f2f2f2"
      />
      <path
        d="M612.3189,624.29528h-43a9.51081,9.51081,0,0,1-9.5-9.5v-27a9.5108,9.5108,0,0,1,9.5-9.5h43a9.5108,9.5108,0,0,1,9.5,9.5v27A9.51081,9.51081,0,0,1,612.3189,624.29528Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#f2f2f2"
      />
      <path
        d="M590.8189,426.03362l-2.31545-2.08878c-8.22262-7.54934-13.65109-12.44922-13.65109-18.553a8.68327,8.68327,0,0,1,8.78159-8.83494,9.44539,9.44539,0,0,1,7.18495,3.37347,9.445,9.445,0,0,1,7.18494-3.37347,8.68328,8.68328,0,0,1,8.78159,8.83494c0,6.10382-5.42854,11.00371-13.65108,18.553Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#ff2e92"
      />
      <path
        d="M590.8189,521.03362l-2.31545-2.08878c-8.22262-7.54934-13.65109-12.44922-13.65109-18.553a8.68327,8.68327,0,0,1,8.78159-8.83494,9.44539,9.44539,0,0,1,7.18495,3.37347,9.445,9.445,0,0,1,7.18494-3.37347,8.68328,8.68328,0,0,1,8.78159,8.83494c0,6.10382-5.42854,11.00371-13.65108,18.553Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#ff2e92"
      />
      <path
        d="M409.15748,505.05222h-43a9.51081,9.51081,0,0,1-9.5-9.5v-27a9.5108,9.5108,0,0,1,9.5-9.5h43a9.5108,9.5108,0,0,1,9.5,9.5v27A9.51081,9.51081,0,0,1,409.15748,505.05222Zm-43-44a7.50835,7.50835,0,0,0-7.5,7.5v27a7.50836,7.50836,0,0,0,7.5,7.5h43a7.50836,7.50836,0,0,0,7.5-7.5v-27a7.50835,7.50835,0,0,0-7.5-7.5Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#3f3d56"
      />
      <path
        d="M387.65748,496.79056l-2.31545-2.08878c-8.22262-7.54934-13.65109-12.44922-13.65109-18.553a8.68327,8.68327,0,0,1,8.78159-8.83494,9.44536,9.44536,0,0,1,7.185,3.37347,9.445,9.445,0,0,1,7.18495-3.37347,8.68329,8.68329,0,0,1,8.78159,8.83494c0,6.10382-5.42855,11.00371-13.65109,18.553Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#ff2e92"
      />
      <polygon
        points="126.965 512.106 138.087 509.46 133.175 465.302 116.76 469.207 126.965 512.106"
        fill="#ffb8b8"
      />
      <path
        d="M360.77092,693.3538h35.92974a0,0,0,0,1,0,0v13.88195a0,0,0,0,1,0,0H374.65285a13.88193,13.88193,0,0,1-13.88193-13.88193v0A0,0,0,0,1,360.77092,693.3538Z"
        transform="translate(673.4245 1105.72874) rotate(166.61944)"
        fill="#2f2e41"
      />
      <path
        d="M351.87477,671.87692l-24.15528-82.78613,30.18506-7.48731,17.708,87.67627a4.49981,4.49981,0,0,1-4.2771,5.38916l-15.00708.44581c-.04517.001-.09009.00195-.135.00195A4.50057,4.50057,0,0,1,351.87477,671.87692Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#2f2e41"
      />
      <polygon
        points="58.09 512.384 69.522 512.383 74.959 468.287 58.086 468.289 58.09 512.384"
        fill="#ffb8b8"
      />
      <path
        d="M291.46749,697.32116h35.92974a0,0,0,0,1,0,0V711.2031a0,0,0,0,1,0,0H305.34942a13.88193,13.88193,0,0,1-13.88193-13.88193v0A0,0,0,0,1,291.46749,697.32116Z"
        transform="translate(383.1015 1220.29161) rotate(179.99483)"
        fill="#2f2e41"
      />
      <path
        d="M291.90943,683.45554a4.41548,4.41548,0,0,1-1.34448-3.1294l-10.41553-82.585a4.50646,4.50646,0,0,1,4.46729-4.52246l23.24829-.16846h.0332a4.51205,4.51205,0,0,1,4.499,4.43115l1.23584,81.3545a4.497,4.497,0,0,1-4.39576,4.56591l-14.11279,1.34131c-.02173.00049-.04346.00049-.06518.00049A4.46755,4.46755,0,0,1,291.90943,683.45554Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#2f2e41"
      />
      <circle cx="61.06468" cy="197.34791" r="24.56103" fill="#ffb8b8" />
      <path
        d="M264.53443,593.42722C248.554,580.94089,248.72315,566.374,254.176,546.91745c1.53613-5.481,3.19409-11.03613,4.94921-16.91748,8.155-27.32471,17.39795-58.2959,13.88892-83.86816l.49536-.06788-.49536.06788c-.96118-7.00391,1.48877-14.3711,6.72119-20.21192,5.23267-5.8418,12.27564-9.07861,19.35547-8.896,7.06738.18848,13.9397,3.80127,18.85425,9.9126,4.89062,6.08155,7.22485,27.46582,7.91675,30.53076,3.17651,14.0669,5.09375,22.55762,6.46875,36.10254,1.62622,16.01319,21.10254,63.89112,27.30883,71.91993a18.99967,18.99967,0,0,1,3.67676,15.13281,17.80508,17.80508,0,0,1-8.45532,12.13525,101.64833,101.64833,0,0,1-32.70606,12.8711,80.1744,80.1744,0,0,1-15.22705,1.46533C291.07081,607.09421,275.91944,602.32272,264.53443,593.42722Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#ff2e92"
      />
      <path
        d="M366.67182,500.8945a9.37694,9.37694,0,0,0-14.04849-3.06268l-18.61127-10.61977-9.21209,9.736,26.49335,14.628a9.42779,9.42779,0,0,0,15.3785-10.68158Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#ffb8b8"
      />
      <path
        d="M338.3003,508.09323l-33.35718-10.47949a22.96148,22.96148,0,0,1-16.00927-20.82813l-1.43531-32.41113a13.57149,13.57149,0,1,1,27.1233-.18262l-.91748,29.81983,35.5017,14.47851a4.50026,4.50026,0,0,1,1.978,6.76026l-7.85718,11.14306a4.48833,4.48833,0,0,1-5.02661,1.69971Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#2f2e41"
      />
      <path
        d="M257.30885,416.11569c1.12231-6.731,1.08105-13.70752,1.041-20.45459-.01074-1.83154-.02173-3.6626-.01049-5.49023.05224-8.49365.57934-17.4961,4.05175-25.78369,3.7793-9.01954,10.77783-15.53272,18.72119-17.42237,8.95557-2.13281,18.6731,2.582,21.59864,10.34522a15.007,15.007,0,1,1,9.14868,27.96924l-.37525.0332-2.92651-7.60547c-.0166.1333-.03247.26807-.04858.40381-.18628,1.57715-.37891,3.20752-1.68091,4.14941h0c-1.62549,1.17578-3.6499,5.04541-3.05835,8.96582a33.80216,33.80216,0,0,0,1.64209,5.88135,32.2768,32.2768,0,0,1,1.74219,6.47949,11.06488,11.06488,0,0,1-2.07227,8.01368,6.18642,6.18642,0,0,1-4.23852,2.43212l-32.9878,3.41114-1.47485-11.01856-9.3833,11.55664Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#2f2e41"
      />
      <path
        d="M428.82677,711.79528h-192a1,1,0,0,1,0-2h192a1,1,0,0,1,0,2Z"
        transform="translate(-235.82677 -188.20472)"
        fill="#ccc"
      />
    </svg>
  )
}
