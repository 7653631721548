export const DataProcessing = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 855.41839 610.77534"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>data processing</title>
      <circle cx="39.53587" cy="110.72662" r="13.63057" fill="#e6e6e6" />
      <circle cx="80.42757" cy="110.72662" r="13.63057" fill="#e6e6e6" />
      <circle cx="121.31927" cy="110.72662" r="13.63057" fill="#e6e6e6" />
      <circle cx="162.21097" cy="110.72662" r="13.63057" fill="#e6e6e6" />
      <circle cx="203.10267" cy="110.72662" r="13.63057" fill="#e6e6e6" />
      <circle cx="243.99437" cy="110.72662" r="13.63057" fill="#e6e6e6" />
      <circle cx="284.88607" cy="110.72662" r="13.63057" fill="#e6e6e6" />
      <path
        d="M488.67447,271.93783H180.32909a5.55346,5.55346,0,0,1-5.54693-5.54693V244.28714a5.55345,5.55345,0,0,1,5.54693-5.54693H488.67447a5.55346,5.55346,0,0,1,5.54693,5.54693V266.3909A5.55347,5.55347,0,0,1,488.67447,271.93783ZM180.32909,240.959a3.3318,3.3318,0,0,0-3.32816,3.32816V266.3909a3.3318,3.3318,0,0,0,3.32816,3.32815H488.67447a3.33179,3.33179,0,0,0,3.32815-3.32815V244.28714a3.33179,3.33179,0,0,0-3.32815-3.32816Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#e6e6e6"
      />
      <path
        d="M246.31694,147.79938a3.19065,3.19065,0,0,1,3.187,3.187V220.049a3.19065,3.19065,0,0,1-3.187,3.187h-67.652a3.19065,3.19065,0,0,1-3.187-3.187V150.98643a3.19065,3.19065,0,0,1,3.187-3.187h67.652m0-3.18705h-67.652a6.3741,6.3741,0,0,0-6.3741,6.3741V220.049a6.3741,6.3741,0,0,0,6.3741,6.3741h67.652a6.37411,6.37411,0,0,0,6.3741-6.3741V150.98643a6.37411,6.37411,0,0,0-6.3741-6.3741Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#e6e6e6"
      />
      <path
        d="M235.76469,165.95293H190.62778a1.59353,1.59353,0,1,1,0-3.187h45.13691a1.59353,1.59353,0,1,1,0,3.187Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M235.76469,177.23755H190.62778a1.59353,1.59353,0,1,1,0-3.187h45.13691a1.59353,1.59353,0,1,1,0,3.187Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M235.76469,188.52139H190.62778a1.59353,1.59353,0,1,1,0-3.187h45.13691a1.59353,1.59353,0,1,1,0,3.187Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M235.76469,199.806H190.62778a1.59353,1.59353,0,1,1,0-3.187h45.13691a1.59353,1.59353,0,1,1,0,3.187Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M235.76469,211.09063H190.62778a1.59353,1.59353,0,1,1,0-3.18706h45.13691a1.59353,1.59353,0,0,1,0,3.18706Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M477.37812,147.79938a3.19065,3.19065,0,0,1,3.187,3.187V220.049a3.19065,3.19065,0,0,1-3.187,3.187h-67.652a3.19065,3.19065,0,0,1-3.187-3.187V150.98643a3.19065,3.19065,0,0,1,3.187-3.187h67.652m0-3.18705h-67.652a6.3741,6.3741,0,0,0-6.3741,6.3741V220.049a6.3741,6.3741,0,0,0,6.3741,6.3741h67.652a6.3741,6.3741,0,0,0,6.3741-6.3741V150.98643a6.3741,6.3741,0,0,0-6.3741-6.3741Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#e6e6e6"
      />
      <path
        d="M466.82588,165.95293H421.689a1.59353,1.59353,0,1,1,0-3.187h45.13692a1.59353,1.59353,0,0,1,0,3.187Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M466.82588,177.23755H421.689a1.59353,1.59353,0,1,1,0-3.187h45.13692a1.59353,1.59353,0,0,1,0,3.187Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M466.82588,188.52139H421.689a1.59353,1.59353,0,1,1,0-3.187h45.13692a1.59353,1.59353,0,0,1,0,3.187Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M466.82588,199.806H421.689a1.59353,1.59353,0,1,1,0-3.187h45.13692a1.59353,1.59353,0,0,1,0,3.187Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M466.82588,211.09063H421.689a1.59353,1.59353,0,1,1,0-3.18706h45.13692a1.59353,1.59353,0,0,1,0,3.18706Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M707.317,280.59077a3.19065,3.19065,0,0,1,3.187,3.187v69.06255a3.19066,3.19066,0,0,1-3.187,3.187H639.665a3.19066,3.19066,0,0,1-3.187-3.187V283.77782a3.19065,3.19065,0,0,1,3.187-3.187h67.652m0-3.187H639.665a6.3741,6.3741,0,0,0-6.3741,6.3741v69.06255a6.3741,6.3741,0,0,0,6.3741,6.3741h67.652a6.37411,6.37411,0,0,0,6.3741-6.3741V283.77782a6.3741,6.3741,0,0,0-6.3741-6.3741Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M696.76476,298.74432H651.62784a1.59353,1.59353,0,1,1,0-3.187h45.13692a1.59353,1.59353,0,0,1,0,3.187Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M696.76476,310.02894H651.62784a1.59353,1.59353,0,0,1,0-3.18706h45.13692a1.59353,1.59353,0,0,1,0,3.18706Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M696.76476,321.31278H651.62784a1.59352,1.59352,0,1,1,0-3.187h45.13692a1.59353,1.59353,0,0,1,0,3.187Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M696.76476,332.59739H651.62784a1.59353,1.59353,0,1,1,0-3.187h45.13692a1.59353,1.59353,0,0,1,0,3.187Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M696.76476,343.882H651.62784a1.59353,1.59353,0,1,1,0-3.187h45.13692a1.59353,1.59353,0,0,1,0,3.187Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M362.73282,226.42308H293.30943a7.08972,7.08972,0,0,1-7.08139-7.08138V169.66541a7.08988,7.08988,0,0,1,7.08139-7.08216h69.42339a7.09,7.09,0,0,1,7.08217,7.08216V219.3417A7.08988,7.08988,0,0,1,362.73282,226.42308ZM293.30943,165.7703a3.89917,3.89917,0,0,0-3.89434,3.89511V219.3417a3.89851,3.89851,0,0,0,3.89434,3.89433h69.42339a3.89917,3.89917,0,0,0,3.89511-3.89433V169.66541a3.89983,3.89983,0,0,0-3.89511-3.89511Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#e6e6e6"
      />
      <path
        d="M350.59,184.81246H305.45305a1.59353,1.59353,0,0,1,0-3.187H350.59a1.59353,1.59353,0,1,1,0,3.187Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M350.59,196.09708H305.45305a1.59353,1.59353,0,0,1,0-3.187H350.59a1.59353,1.59353,0,1,1,0,3.187Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M350.59,207.38092H305.45305a1.59353,1.59353,0,0,1,0-3.187H350.59a1.59353,1.59353,0,1,1,0,3.187Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M593.09111,253.95025,526.41486,234.6141a7.08971,7.08971,0,0,1-4.82882-8.77351l13.83608-47.71054a7.08988,7.08988,0,0,1,8.77372-4.82958l66.67625,19.33615a7.09,7.09,0,0,1,4.82935,8.77447l-13.83608,47.71055A7.08988,7.08988,0,0,1,593.09111,253.95025Zm-49.78294-77.58884a3.89916,3.89916,0,0,0-4.82511,2.65631L524.647,226.72827a3.89851,3.89851,0,0,0,2.65557,4.8249l66.67624,19.33614a3.89918,3.89918,0,0,0,4.82565-2.65535l13.83608-47.71054a3.89985,3.89985,0,0,0-2.65609-4.82587Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#e6e6e6"
      />
      <path
        d="M593.01835,210.60411l-43.3508-12.57175a1.59353,1.59353,0,0,1,.88767-3.06094L593.906,207.54318a1.59352,1.59352,0,1,1-.88768,3.06093Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M589.87531,221.44219,546.5245,208.87043a1.59352,1.59352,0,1,1,.88767-3.06093L590.763,218.38125a1.59353,1.59353,0,0,1-.88767,3.06094Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M586.73248,232.27951l-43.35081-12.57175a1.59353,1.59353,0,0,1,.88767-3.06094l43.35081,12.57176a1.59352,1.59352,0,0,1-.88767,3.06093Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <rect
        x="156.10589"
        y="607.77534"
        width="699.3125"
        height="3"
        fill="#e6e6e6"
      />
      <polygon
        points="562.14 603.085 448.421 603.085 452.276 552.973 558.285 552.973 562.14 603.085"
        fill="#e6e6e6"
      />
      <rect
        x="442.63878"
        y="597.3034"
        width="127.21043"
        height="11.5646"
        fill="#e6e6e6"
      />
      <path
        d="M895.37073,374.74021H460.73514a11.56458,11.56458,0,0,0-11.56458,11.56458h0V651.3265H906.93533V386.30479a11.56457,11.56457,0,0,0-11.56456-11.56458Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#3f3d56"
      />
      <path
        d="M449.17056,651.3265v43.36719a11.56457,11.56457,0,0,0,11.56458,11.56457H895.37073a11.56459,11.56459,0,0,0,11.5646-11.56457h0V651.3265Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#e6e6e6"
      />
      <rect
        x="300.00891"
        y="254.22076"
        width="412.47016"
        height="231.29169"
        fill="#e6e6e6"
      />
      <circle cx="506.244" cy="533.69821" r="11.56459" fill="#3f3d56" />
      <circle cx="312.86987" cy="261.79534" r="2.19466" fill="#fff" />
      <circle cx="321.20026" cy="261.79534" r="2.19466" fill="#fff" />
      <circle cx="329.53064" cy="261.79534" r="2.19466" fill="#fff" />
      <rect
        x="310.6752"
        y="269.25275"
        width="389.91608"
        height="194.24707"
        fill="#fff"
      />
      <path
        d="M508.885,443.41111a1.37973,1.37973,0,0,0-1.37842,1.37842v69.67578a1.37973,1.37973,0,0,0,1.37842,1.37841H589.3493a1.37973,1.37973,0,0,0,1.37842-1.37841V444.78953a1.37973,1.37973,0,0,0-1.37842-1.37842Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#e6e6e6"
      />
      <path
        d="M638.08075,443.63523a1.37984,1.37984,0,0,0-1.37891,1.37842v69.45166a1.37984,1.37984,0,0,0,1.37891,1.37841h80.208a1.37963,1.37963,0,0,0,1.37793-1.37841V445.01365a1.37963,1.37963,0,0,0-1.37793-1.37842Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#e6e6e6"
      />
      <path
        d="M765.92157,442.9072a1.37963,1.37963,0,0,0-1.37793,1.37842v70.17969a1.37962,1.37962,0,0,0,1.37793,1.37841h81.042a1.37962,1.37962,0,0,0,1.37793-1.37841V444.28562a1.37963,1.37963,0,0,0-1.37793-1.37842Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#e6e6e6"
      />
      <path
        d="M510.35614,550.333a2.84986,2.84986,0,0,0,0,5.69971h75.65234a2.84986,2.84986,0,0,0,0-5.69971Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#e6e6e6"
      />
      <path
        d="M510.35614,563.88865a2.8501,2.8501,0,0,0,0,5.70019h34.062a2.8501,2.8501,0,0,0,0-5.70019Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M640.94012,550.333a2.84986,2.84986,0,0,0,0,5.69971h75.65235a2.84986,2.84986,0,0,0,0-5.69971Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#e6e6e6"
      />
      <path
        d="M640.94012,563.88865a2.8501,2.8501,0,0,0,0,5.70019h34.0625a2.8501,2.8501,0,0,0,0-5.70019Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
      <path
        d="M768.26044,550.333a2.84986,2.84986,0,0,0,0,5.69971h75.65234a2.84986,2.84986,0,0,0,0-5.69971Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#e6e6e6"
      />
      <path
        d="M510.35614,536.333a2.84986,2.84986,0,0,0,0,5.69971h75.65234a2.84986,2.84986,0,0,0,0-5.69971Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#e6e6e6"
      />
      <path
        d="M640.94012,536.333a2.84986,2.84986,0,0,0,0,5.69971h75.65235a2.84986,2.84986,0,0,0,0-5.69971Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#e6e6e6"
      />
      <path
        d="M768.26044,536.333a2.84986,2.84986,0,0,0,0,5.69971h75.65234a2.84986,2.84986,0,0,0,0-5.69971Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#e6e6e6"
      />
      <path
        d="M768.26044,563.88865a2.8501,2.8501,0,0,0,0,5.70019H802.322a2.8501,2.8501,0,0,0,0-5.70019Z"
        transform="translate(-172.29081 -144.61233)"
        fill="#ff2e92"
      />
    </svg>
  )
}
